// Blocking time optimized aca recommendations from
// https://github.com/nuxt/nuxt.js/discussions/9061#discussioncomment-539483

// Importing dependencies - statically ------
import { Context } from '@nuxt/types';
import { setSafeTimeout } from '@/node_modules/@osp/design-system/assets/js/utilities/timeout';
import { useTrackingStore } from '~/@api/store/trackingApi';
import { clientInitUxStore, useUxStore } from '~/@api/store/uxApi';
import clientPluginOnNuxtReady from '~/plugins/clientPluginHandler';
import { importPerformanceTracking } from '~/app-utils/dynamic-imports';

async function PluginIntegration(context: Context) {
	await clientInitUxStore(context.store);

	const { api: uxApi, state: uxState } = useUxStore(context.store);

	context.$router.beforeResolve((to, from, next) => {
		if (uxState.isPageSwitchHookActive && to.fullPath !== from.fullPath) {
			uxApi.setFooterVisible(false);
			uxApi.setPageSwitchProgressStatus(true);
		}

		if (context.$config?.env?.TRACKING_PERFORMANCE && typeof (window as any).LUX === 'object') {
			const { api: trackingApi } = useTrackingStore(context.store);

			Promise.all([
				trackingApi.resetEventForSPANavigation(),
				trackingApi.resetReadyToSendState(),
			]).then(() => next());
		} else {
			next();
		}
	});

	context.$router.afterEach((to, from) => {
		// Give browser some milliseconds to perform the DOM updates
		setSafeTimeout(() => {
			uxApi.setPageSwitchProgressStatus(false);

			setSafeTimeout(() => {
				uxApi.setFooterVisible(true);
			}, 250);
		}, 150);

		if (to.meta.pageType === from.meta.pageType) {
			window.$nuxt.$emit('reRegisterClsComponents');
		}

		// If page switch hook was deactivated, turn it on again (initial default) after page switch
		if (!uxState.isPageSwitchHookActive) {
			uxApi.setPageSwitchHookActive(true);
		}

		if (context.$config?.env?.TRACKING_PERFORMANCE && typeof (window as any).LUX === 'object') {
			const pageTypeLabel = context.route.meta.find((item) => item.pageType)?.pageType;

			if (pageTypeLabel && (window as any).LUX.label !== pageTypeLabel) {
				importPerformanceTracking().then(
					({ performanceTracking }) => performanceTracking?.setLabel(undefined, pageTypeLabel),
				);
			}
		}
	});
}

// Defer until Nuxt is ready
export default function (context: Context, _) {
	clientPluginOnNuxtReady(() => PluginIntegration(context));
}
