import { Context } from '@nuxt/types';
import { useUserStore } from '~/@api/store/userApi';
import { PageTypes } from '~/@api/store.types';

export default async (context: Context) => {
	const { api: userApi, state: userState } = useUserStore(context.store);
	const localizedPageTypes = [PageTypes.HOME, PageTypes.CATEGORY];
	const pageType = [...(context.route.matched || [])]
		.reverse()
		.find((route) => route.meta?.pageType)?.meta.pageType;
	let localizedGender;

	if (!localizedPageTypes.includes(pageType)) return;

	if (pageType === PageTypes.CATEGORY) {
		localizedGender = context.route.params.categoryPath?.split('-')[0]?.toLowerCase();
	} else if (pageType === PageTypes.HOME) {
		localizedGender = context.route.params.gender?.toLowerCase();
	}

	const gender =
		userState.user.genders.find((gender) => gender.name.toLowerCase() === localizedGender)?.code ||
		'NEUTRAL';

	if (gender && gender !== userState.user.gender?.code) {
		await userApi.setGender(gender);
	}
};
