import { Store } from 'vuex';
import { LoginData, RootState, UserActionResponse, UserActionType } from '~/@api/store.types';
import {
	USER_A_CHANGE_EMAIL,
	USER_A_CHANGE_PASSWORD,
	USER_A_FORGOTTEN_PASSWORD,
	USER_A_GENDER,
	USER_A_GUEST_REGISTRATION,
	USER_A_LOGIN,
	USER_A_REGISTRATION,
	USER_A_UPDATE,
	USER_A_REMOVE_CREDIT_CARD,
	USER_G_HAS_ERROR,
	USER_M_SAVE,
	USER_M_SAVE_RESPONSE,
	USER_M_SAVE_PREVIOUS_GENDER,
} from '~/@constants/store';
import {
	RegistrationRequest,
	User,
	UserChangeEmailRequest,
	UserChangePasswordRequest,
} from '~/generated/hybris-raml-api';

export default function (store: Store<RootState>) {
	return {
		login: (data: LoginData, isCheckout = false) =>
			store.dispatch(USER_A_LOGIN, { data, isCheckout }, { root: true }),

		registration: (data: RegistrationRequest, isCheckout = false, message = '') =>
			store.dispatch(USER_A_REGISTRATION, { data, isCheckout, message }, { root: true }),

		guestRegistration: (email: string) =>
			store.dispatch(USER_A_GUEST_REGISTRATION, { email }, { root: true }),

		forgottenPassword: (email: string) =>
			store.dispatch(USER_A_FORGOTTEN_PASSWORD, { email }, { root: true }),

		update: () => store.dispatch(USER_A_UPDATE, null, { root: true }),

		save: (user: User) => store.commit(USER_M_SAVE, user, { root: true }),

		saveResponse: (details: UserActionResponse, type: UserActionType) =>
			store.commit(USER_M_SAVE_RESPONSE, { details, type }, { root: true }),

		hasError: (type?: UserActionType): boolean => store.getters[USER_G_HAS_ERROR](type),

		setGender: (code: string) => store.dispatch(USER_A_GENDER, code, { root: true }),

		setPreviousGender: () =>
			store.commit(USER_M_SAVE_PREVIOUS_GENDER, store.state.user.user.gender, { root: true }),

		changeEmail: (data: UserChangeEmailRequest) =>
			store.dispatch(USER_A_CHANGE_EMAIL, data, { root: true }),

		changePassword: (data: UserChangePasswordRequest) =>
			store.dispatch(USER_A_CHANGE_PASSWORD, data, { root: true }),

		removeCreditCard: (paymentCode: string) =>
			store.dispatch(USER_A_REMOVE_CREDIT_CARD, { code: paymentCode }, { root: true }),
	};
}
